
export class DedaloPageAnalyzerService {

    private pageData: any;

    constructor() {}

    private async analyzeAssets(): Promise<any[]> {
        // Trova tutti gli elementi <link> e <script> con attributo href o src
        let assets: any = [
            ...document.querySelectorAll('link[rel="stylesheet"]'),
            ...document.querySelectorAll('script[src]'),
            ...document.querySelectorAll('img[src]'),
            ...document.querySelectorAll('video[src]'),
            ...document.querySelectorAll('audio[src]'),
        ];
        // Filtra gli elementi che non hanno attributo href o src
        assets = assets.filter((asset: any) => asset.href || asset.src);
        // Filtra gli elementi che sono un base64
        assets = assets.filter((asset: any) => {
            const url = asset.href || asset.src;
            // console.log(url, url.startsWith('data:'));
            return !url.startsWith('data:');
        });
        // Ottieni le dimensioni di tutti gli asset
        const sizes = await Promise.all(assets.map((asset: any) => this.getAssetSize(asset.href || asset.src)));
    
        // Creare un oggetto con le dimensioni degli asset ed il nome dell'asset
        return assets.map((asset: any, index: number) => ({
            hostname: new URL(asset.href || asset.src).hostname,
            type: this.getAssetType(asset.href || asset.src),
            name: asset.href || asset.src,
            size: sizes[index],
        }));
    }

    public async analyzePage() {
        const urlData = {
            url: window.location.href,
            hostname: window.location.hostname,
            pathname: window.location.pathname
        }
        // Get the page title
        const pageTitle = document.title;
        // Get page size (HTML only)
        const pageSize = document.documentElement.innerHTML.length;
        const assets = await this.analyzeAssets();
        const totalSize = assets.reduce((acc: number, asset: any) => acc + (asset.size || 0), pageSize);
        this.pageData = {
            ...urlData,
            pageTitle,
            pageSize,
            totalSize,
            assets,
        };
    }

    public getPageData(): any {
        return this.pageData;
    }

    private getAssetType(assetUrl: string): string {
        const extension = assetUrl.split('.').pop();
        switch (extension) {
          case 'js':
            return `script/${extension}`;
          case 'css':
            return `stylesheet/${extension}`;
          case 'png':
          case 'jpg':
          case 'jpeg':
          case 'gif':
          case 'webp':
            return `image/${extension}`;
          default:
            return `other/${extension}`;
        }
    }

    private async getAssetSize(assetUrl: string): Promise<number | null> {
        try {
            // Effettua una richiesta HEAD
            const response = await fetch(assetUrl, { method: 'GET', headers: { 'Range': 'bytes=0-1'} });
            if (!response.ok) {
                console.warn(`Could not compute stats for ${assetUrl}:`, response.statusText);
                return null;
            }
            if(!response.headers) {
                console.warn(`Could not compute stats for ${assetUrl}:`, 'Headers not found');
                return null;
            }
            if (!response.headers.has('Content-Range') || !response.headers.get('Content-Range')) {
                console.warn(`Could not compute stats for ${assetUrl}:`, 'Content-Range header not found');
                return null;
            }
            const size = response.headers.get('Content-Range')?.split('/')[1];
            return size ? parseInt(size, 10) : null;
        } catch (error: any) {
            console.warn(`Could not compute stats for ${assetUrl}:`, error.message);
            return null;
        }
    }
}