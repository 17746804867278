import {
    BADGE_CONTAINER_ID,
    BADGE_LOADER_ID,
    BADGE_ICON_ID,
    BADGE_LOGO_ID,
    BADGE_BODY_ID,
    BADGE_OPEN_MODAL_BTN_ID,
    POWERED_BY_URL,
    POWERED_BY_NAME,
} from '../constants';
import logo from '../assets/imgs/logo.png';
import infoIcon from '../assets/imgs/info.svg';

export const DEDALOAI_BADGE_TEMPLATE: string = `
  <!-- DedaloAI Badge START -->
  <div id="${BADGE_CONTAINER_ID}" class="ddl-floating">
    <div id="ddlai-badge-logo-or-loader">
      <button id="${BADGE_LOGO_ID}">
        <img src="${logo}" alt="DedaloAI Logo" />
      </button>
      <div id="${BADGE_LOADER_ID}">
        <div class="ddlai-ldr-container">
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
          <div class="ddlai-ldr-particle"></div>
        </div>      
      </div>
    </div>
    <div id="${BADGE_BODY_ID}">
      <div id="ddlai-badge-body-l"">
          <div id="ddlai-badge-message">
            <div id="ddlai-badge-text">
              <!-- <span id="${BADGE_ICON_ID}">
              </span> -->
              <span id="ddlai-badge-text-content">
              </span>
            </div>
          </div>
          <div id="ddlai-badge-footer">
            Powered by <a href="${POWERED_BY_URL}" target="_blank">${POWERED_BY_NAME}</a>        
          </div>
        </div>
        <div id="ddlai-badge-body-r">
          <button id="${BADGE_OPEN_MODAL_BTN_ID}">
            <img src="${infoIcon}" alt="DedaloAI Session Info" />
          </button>
        </div>
    </div>

  </div>
  <!-- DedaloAI Badge END -->
`;